<script>
import LoadSections from '@/mixins/load-sections';
import View from '@/views/View';

export default {
  name: 'KDAProject',
  metaInfo: { title: 'Project' },
  extends: View,

  mixins: [
    LoadSections(['KDAProjectHeader', 'KDAProjectQuote', 'KDAProjectList', 'KDABookBanner'])
  ],

  props: {
    id: {
      type: String,
      default: 'project'
    }
  },

  mounted() {
    window.gtag('event', 'page_view', {
      'page_title': 'Project'
    })
  },
};
</script>
